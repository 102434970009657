import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { ReactHiererchyChart } from "react-hierarchy-chart";
import moralis from "moralis";
import axios from "axios";
import { ethers, JsonRpcProvider } from "ethers";
import Logo from "./img/logo-principal.png";
import { Container, Col, Row } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { TiThMenu } from "react-icons/ti";
import { BiCopy } from "react-icons/bi";
import PacmanLoader from "react-spinners/PacmanLoader";
import {useParams} from 'react-router-dom'
import Dropdown from "./components/Dropdown";
import {useNavigate} from 'react-router-dom';
import WalletBase from './img/walletBase.png'

function App() {
  const navigate = useNavigate();

  const [tree, setTree] = useState(null);
  const [isVertical, setIsVertical] = useState(false);
  const wallet = '0xd592673053a14308C376D0125133A6770c52e6e5'
  const { address, chainId } = useParams();
  const [inputAddress, setInputAddress] = useState(address)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [chainData, setChainData] = useState([])
  const [selectedChain, setSelectedChain] = useState(chainId);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const walletRef = useRef(null);

 

  const handleSelectChain = (selectedChain) => {
    setSelectedChain(selectedChain);
  };


  async function getChain(){
    const url = 'https://27zbfe9ts0.execute-api.us-west-2.amazonaws.com/Test/chains';
      axios.get(url)
        .then(response => {
          setChainData(response.data.body)
          
        })
        .catch(e => {
          console.error(e);
        });
  }

/*   async function fetchTree(searchAddress) {
    setIsLoading(true)
    try {
      const url =
        `https://27zbfe9ts0.execute-api.us-west-2.amazonaws.com/Test/tree?walletRoot=${searchAddress}&depth=0&chain=${selectedChain}`;
        console.log('url to fetch', url)
      axios
        .get(url)
        .then((response) => {
          console.log("tree", response.data.tree);
          setTree([response.data.tree]);
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false)
          // Aquí puedes manejar cualquier error que ocurra durante la solicitud
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false)
    }
  } */

  useEffect(() => {
    startWebsocket(chainId, address)
    getChain()
  }, [address, chainId]);

  useEffect(() => {
    console.log('entra tree')
  }, [tree])
  

  

  useEffect(() => {
    if (!isLoading) {
      if (walletRef.current) {
        walletRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [isLoading, isVertical]);

  const handleError = (e) => {
    e.target.src = "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"; // Aquí pon el url de tu imagen personalizada
  };


    const handleChange = (e) => {
      const input = e.target.value.trim();
      
      // Llamar a la función validarDireccion con el valor del input
      let patron = /^(0x)?[0-9a-fA-F]{40}$/;
      const isValidAddress = patron.test(input);
      setInputAddress(e.target.value)
      setButtonDisabled(!isValidAddress);
      // Si es válido, borrar el error y pasar el valor al callback setAddress
      if (isValidAddress) {
        setError("");
        
      } else {
        // Si no es válido, mostrar un mensaje de error y pasar una cadena vacía al callback setAddress
        setError("The address is not valid,  please check it and try again");
       
      }
    };

  function handleCopy(address) {
    navigator.clipboard.writeText(address);
    alert('copied')
  }

  const handleOnClick = () => navigate(`/search/${inputAddress}/${selectedChain}`);




  const handleViewData = (token_wallet) => {
    // Buscar la cadena seleccionada en chainData
    const selectedChainData = chainData.find((chain) => chain.chain === selectedChain);
  
    if (selectedChainData) {
      // Si se encontró la cadena, imprimir su rpcUrl
      console.log("RPC URL:", selectedChainData.scanner);

      //matic mumbai
      const url = selectedChainData.scanner + 'address/'  + token_wallet;

      console.log('urLINK', url)
      window.open(url, '_blank');
    } else {
      // Si no se encontró la cadena, puedes mostrar un mensaje de error o hacer otra acción.
      console.log("Cadena no encontrada en chainData.");
    }
  }

  const startWebsocket = async (_chainId, _address) => {
    setIsLoading(true);
    try {
        const websocketEndpoint = `${process.env.REACT_APP_WSC}?walletRoot=${_address}&depth=0&chain=${_chainId}`;
        const websocket = new WebSocket(websocketEndpoint);
        let messageArray = [];
        let isEndReceived = false; // Flag to track if "END" message is received

        websocket.onopen = () => {
            console.log('Conexión establecida al WebSocket', websocket);
        };

        websocket.onmessage = async (event) => {
            const message = event.data;
          

            if (!isEndReceived) {
                if (message === "END") {
                    isEndReceived = true;
                    //const fullMessage = messageArray.join('');º
                    //console.log('Mensaje completo:', fullMessage);
                    let fullMessage = "";
                    messageArray.forEach((message)=>{
                      const buffer =Buffer.from(message, 'base64');
                      const decodedChunk = buffer.toString('utf-8');
                      fullMessage += decodedChunk;
                    })

                    try {
                        const parsedData = JSON.parse(fullMessage);
                        setTree([parsedData]);
                        console.log(parsedData);
                        
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }

                    setIsLoading(false);
                } else {
                    messageArray.push(message);
                }
            }
        };

        websocket.onerror = (error) => {
            console.error('Error en la conexión WebSocket:', error);
            setIsLoading(false);
        };

        return () => {
            websocket.close();
        };
    } catch (error) {
        alert(error);
        setIsLoading(false);
    }
};


  return (
    <div className="app-container">
      <Container className="d-flex align-items-center justify-content-center">

      
      <Row className="navbar-search">
        <Col sm={12} md={3} style={{display: 'flex', justifyContent: 'center'}}>
        <a href="https://dappsfactory.io" target="_blank" rel="noreferrer" >
          <img src={Logo} alt="dApps Factory" className="logo-nav d-none d-md-block" />
          <img src={Logo} alt="dApps Factory" className="logo-nav-mini d-sm-block d-md-none" />
        </a>
        </Col>
        <Col sm={7} md={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <Dropdown data={chainData} func={handleSelectChain} />
        <div className="search-bar-container">
          <input
              type="text"
              placeholder='Search address here'
              className="search-bar-nav"
              onChange={handleChange}
              disabled={isLoading}
              />
          <button className="search-btn" onClick={handleOnClick} disabled={buttonDisabled}>Search</button>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <label style={{color: 'white', textAlign: 'center', fontWeight: '400', marginTop: '1rem', marginBottom: '1rem'}} className="d-none d-md-block"><span style={{fontWeight: 'bold'}}>Wallet:</span> {address}  <BiCopy style={{cursor: 'pointer'}} onClick={() => handleCopy(address)}/></label> 
       
        <label style={{color: 'white', textAlign: 'center', fontWeight: '400',fontSize: '14px', marginTop: '.25rem', marginBottom: '.25rem'}} className="d-sm-block d-md-none"><span style={{fontWeight: 'bold'}}>Wallet:</span> {address}</label>
              </Col>
        <Col sm={1} md={3}>
          <TiThMenu className="Menu" onClick={() => setIsVertical(false)} />
          <TiThMenu
            
            className="Menu-inverted"
            onClick={() => setIsVertical(true)}
          />
        </Col>
        <Col md={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        </Col>
      </Row>
      </Container>
      {/* CARD TEST */}

      <div style={{overflowX: 'auto', width: '100%'}}>
        <Container>
         
        </Container>
         <div className="hierarchy-viewer" style={{marginLeft: '2rem'}}>
          {!isLoading && tree ? (
            <ReactHiererchyChart
              nodes={tree}
              lineColor="red"
              direction={isVertical ? "vertical" : "horizontal"}
              randerNode={node => {
               
                return (
                 
                  <>
                    <div   className={`contenedor`}>
                      <div className="contenedor_tarjeta">
                        <div className="remplazohref">
                          <figure style={{height: '100%'}}>
                          {node.metadata || node.token_wallet ? (
                            <div className="frontal">
                              <div className="img-container">
                                {node.metadata !== null && node.token_owner? (
                                  <img
                                    src={(((JSON.parse(node?.metadata)?.image)?.slice(0, 12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(node?.metadata)?.image)?.slice(12)) : (((JSON.parse(node?.metadata)?.image)?.slice(0, 7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(node?.metadata)?.image)?.slice(7)) : JSON.parse(node?.metadata)?.image }


                                    alt="card-user"
                                    style={{
                                      borderRadius: "15px",
                                    }}
                                    onError={handleError}
                                  />
                                ) : <img src={WalletBase} alt='' ref={walletRef} id='wallet-inicio'/>}
                                <div className="title-frontal">
                                  {node.metadata && (
                                    <p style={{ color: "white" }}>
                                      {JSON.parse(node.metadata).name}
                                    </p>
                                  )}
                                  
                                </div>
                              </div>
                            </div>): ( <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930" alt='' />)}
                            <figcaption className="trasera">
                              
                              <Container className="back-container" >
                                <div className="back">
                                  {node.token_wallet && (
                                    <label className="label-back">
                                     Wallet Address: 
                                     <span className="span-back">{node.token_wallet}</span>
                                      <Tooltip title="Copy">
                                        <span>
                                          <BiCopy style={{cursor: 'pointer'}} onClick={() => handleCopy(node.token_wallet)}/>
                                        </span>
                                      </Tooltip>
                                    </label>
                                  )}

                                  {node.token_owner && (
                                    <label className="label-back">
                                      Owner Address:
                                        <span className="span-back">{node.token_owner}</span> 
                                      <Tooltip title="Copy">
                                        <span>
                                        <BiCopy style={{cursor: 'pointer'}} onClick={() => handleCopy(node.token_owner)}/>
                                        </span>
                                       </Tooltip>
                                    </label>
                                  )}

                                  {node.token_id && (
                                    <label className="label-back"> Token Id: <span className="span-back">{node.token_id}</span> </label>
                                  )}

                                  {node.amount && (
                                    <label className="label-back"> Token Amount: <span className="span-back"> {node.amount}</span></label>
                                  )}

                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button className="view-btn" onClick={()=>handleViewData(node.token_owner)}>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </Container>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </>
                );
            
              }}
            />
          ) : (
            <Container className="loader-container">
              <PacmanLoader
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="#ba74f2"
                className="loader"
              />
            </Container>
          )}
        </div> 
      </div>
      
    </div>
  );
}

export default App;

